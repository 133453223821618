import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CurrencyPipe, NgIf } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { PaymentConfigModel } from '@core/models/payment-config.model';
import { ExpansionPanelComponent } from '@payment-app/components/payment-methods/expansion-panel/expansion-panel.component';
import {
  FormPayment,
  PaymentMethod,
  PaymentMethods,
} from '@payment-app/core/models';
import { CardFormComponent } from '@payment-app/components/payment-methods/card-form/card-form.component';
import { CardForm } from '@payment-app/components/payment-methods/card-form/card-form.interface';
import { PaymentDataService } from '../../../../screens/payment-process/components/payment-data/payment-data.service';
import { TokenizationFormComponent } from './tokenization-form/tokenization-form.component';
import { NuveiCardCheckoutService } from './nuvei-card-checkout.service';
import { PaymentRequestService } from 'src/app/payment/payment-request/payment-request.service';

@Component({
  selector: 'nuvei-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    MatButtonModule,
    NgIf,
    ExpansionPanelComponent,
    CardFormComponent,
    TokenizationFormComponent,
  ],
  templateUrl: './nuvei-card.component.html',
  styleUrls: ['./nuvei-card.component.scss'],
})
export class NuveiCardComponent implements OnDestroy, OnInit {
  @Input()
  isCreditCard!: boolean;
  readonly #paymentDataService = inject(PaymentDataService);
  readonly #nuveiCardCheckoutService = inject(NuveiCardCheckoutService);
  #subscription: Subscription;
  protected paymentMethod!: PaymentMethod;
  protected nuveiCardFormGroup: FormGroup<CardForm>;
  protected showCheckout = false;
  readonly #paymentRequestService = inject(PaymentRequestService);
  paymentRequest = this.#paymentRequestService.paymentRequest;

  // TODO: only for testing
  readonly #activatedRoute = inject(ActivatedRoute);
  isTesting = false;
  errorMessage!: string | null;

  constructor() {
    this.isTesting = this.#activatedRoute.snapshot.queryParams.test === 'true';
  }

  ngOnInit(): void {
    this.initNuveiCardForm();

    this.paymentMethod = this.isTesting
      ? PaymentMethods.get(FormPayment.DEBIT_CREDIT_CARD)
      : this.isCreditCard
        ? PaymentMethods.get(FormPayment.CREDIT_CARD)
        : PaymentMethods.get(FormPayment.DEBIT_CARD);
    this.paymentMethod.disabled = this.disabledMethod();
  }

  ngOnDestroy(): void {
    this.#subscription?.unsubscribe();
  }

  async openModal() {
    this.#nuveiCardCheckoutService.openModalCheckout(this.isCreditCard);
  }

  get amount() {
    return this.paymentRequest.aplly_bonus
      ? this.paymentRequest?.data?.amount
      : this.paymentRequest?.data?.amount;
  }

  onSaveCard(value: boolean) {
    this.showCheckout = !value;

    if (!value) {
      this.#paymentDataService.callToAction(() => {
        this.#nuveiCardCheckoutService.openModalCheckout(this.isCreditCard);
      });
    }
  }

  typeCardSelected(value: boolean) {
    this.isCreditCard = value;
  }

  private initNuveiCardForm() {
    this.nuveiCardFormGroup = new FormGroup<CardForm>({
      idType: new FormControl<string>(''),
      idValue: new FormControl<string>('', [Validators.required]),
      customerName: new FormControl<string>(''),
      customerEmail: new FormControl<string>(''),
      cardNumber: new FormControl<string>(''),
      cardExpirationMonth: new FormControl<string>(''),
      cardExpirationYear: new FormControl<string>(''),
      cardCvv: new FormControl<number | null>(null),
      installments: new FormControl<number>(0),
    });
  }

  private disabledMethod() {
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length && this.paymentRequest.gateway_active == 'Paymentez') {
      return true;
    }
    return false;
  }
}
