import { Retention } from '@payment-app/components/info-amount-to-pay/components/retentions/retentions.service';
import { SplitTransactionPaymentezModel } from '@core/models/paymentez/split-transaction-paymentez.model';

export const typeDocument = [
  { code: 'CC', name: 'Cédula de Ciudadanía' },
  { code: 'CE', name: 'Cédula de Extranjería' },
  { code: 'PPN', name: 'Cédula de Pasaporte' },
  { code: 'NIT', name: 'NIT' },
  { code: 'TI', name: 'Tarjeta de identidad' },
];

export interface BankTransferTransactionRequest {
  carrier: {
    id: string;
    extra_params: {
      bank_code: string;
      response_url: string;
      user: {
        name: string;
        fiscal_number: string;
        type: string;
        type_fis_number: string;
        ip_address: string;
      };
      split?: SplitTransactionPaymentezModel
    };
  };
  user: {
    id: string;
    email: string;
  };
  order: {
    dev_reference: string;
    amount: number;
    bonus?: string;
    manual_bonus?: string;
    vat: string;
    description: string;
  };
  dataRetentions?: Retention;
}

export interface BankTransferTransactionResponse {
  transaction: {
    bank_url: string;
  };
}
